import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-844f1144"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.moveEnter && _ctx.moveEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.moveLeave && _ctx.moveLeave(...args)))
  }, [
    (_ctx.isStatue)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.moveUrl
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.isStatue)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.url,
          alt: ""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 32))
}