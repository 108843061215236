
import { defineComponent, ref, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'Image',
  props: {
    url: {
      required: true,
      type: String
    },
    moveUrl: {
      required: true,
      type: String
    },
    statue: {
      required: true,
      type: Boolean
    }
  },
  setup (props) {
    const isStatue = ref<boolean>(props.statue)
    const moveImg = reactive({
      moveEnter: () => {
        isStatue.value = true
      },
      moveLeave: () => {
        isStatue.value = false
      }
    })
    return {
      isStatue,
      ...toRefs(moveImg)
    }
  }
})
